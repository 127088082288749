html.dark {
  background-color: rgb(15, 23, 42);
}

.rainbow-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.rainbow-box {
  width: 100px;
  height: 70px;
  margin: 3px;
  border-radius: 10px;
  background: linear-gradient(45deg, #ff0000, #ff9900, #33cc33, #3399ff, #9900cc);
  background-size: 500% 500%;
  animation: rainbow 3s linear infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
